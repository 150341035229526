/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import appInfo from '../../app-info';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import DynamicForm from '../../components/dynamicform/dynamicform';
import ImgLogo from '../../Images/modify_table_style.svg';
import './profile.scss';
import { useAuth } from '../../contexts/auth';

export default function App() {

    const {user} = useAuth();
    const [formData, setFormData] = useState(null);
    const [formSchemaRuoliProfilo, setFormSchemaRuoliProfilo] = useState(null);
    const [formDataRuoliProfilo, setFormDataRuoliProfilo] = useState(null);

    const axiosInstance = axios.create({ baseURL: appInfo.urlWebApi, timeout: 3000, headers: { 'X-Custom-Header': 'foobar' } });
    const axiosParameters = { internalKey: user.internalKey, userType: user.userType, id: user.id };

    useEffect(() => {
        const fetchData = async () => {
            await GetDataFromApi('GetProfilo', axiosParameters).then(function (result) { setFormData(result) });
            await GetDataFromApi('GetSchemaRuoliProfilo', axiosParameters).then(function (result) { setFormSchemaRuoliProfilo(result) });
            await GetDataFromApi('GetRuoliProfilo', axiosParameters).then(function (result) { setFormDataRuoliProfilo(result) });
        }
        fetchData();
    }, []);


    const handleFormDatiSubmit = async () => {
        await PutDataFromApi('SaveProfilo', axiosParameters, formData);
    };
    const handleFormRuoliSubmit = async () => {
        await PutDataFromApi('SaveRuoliProfilo', axiosParameters, formDataRuoliProfilo);
    };


    async function GetDataFromApi(apiName, parameters) {
        var value = null;

        await axiosInstance.get('/api/Profilo/' + apiName, { params: parameters })
            .then(function (response) { value = response.data; })
            .catch(function () { notify('Errore dururante il caricamento dei dati', 'Error', 2000); return null; })
            .finally(function () { });

        return value;
    };
    async function PutDataFromApi(apiName, parameters, formData) {
        await axiosInstance.post('/api/Profilo/' + apiName, formData, { params: parameters }, { headers: { 'Content-Type': 'application/json' } })
            .then(function (response) {
                if (response.data.result === 1)
                    { notify('Salvataggio avvenuto con successo', 'Success', 2000); }
                else
                    { notify('Errore dururante il salvataggio', 'Alert', 2000); }
            })
            .catch(function () { notify('Errore dururante il salvataggio', 'Error', 2000); return null; })
            .finally(function () { });
    };


    return (
        <React.Fragment>
            <h4 className={'content-block'}>SCHEDA DI PROFILAZIONE</h4>
            <div className={'content-block dx-card responsive-paddings'}>
                <div className={'form-avatar'}>
                    <img alt={''} src={ImgLogo} style={{ padding: '10px' }}/>
                </div>
                <br></br>
                <span><b>La compilazione dei seguenti dati permette l'accesso alle funzionalita' operative del portale</b></span>
            </div>
            <div className={'content-block dx-card responsive-paddings'}>
                {formData && <DynamicForm schema={formSchemaDatiGenerali} dataform={formData} onSubmit={handleFormDatiSubmit} />}
            </div>
            <div className={'content-block dx-card responsive-paddings'}>
                {formDataRuoliProfilo && <DynamicForm schema={formSchemaRuoliProfilo} dataform={formDataRuoliProfilo} onSubmit={handleFormRuoliSubmit} />}
            </div>
        </React.Fragment>
    );
};


const formSchemaDatiGenerali = [
    {
        sectionTitle: 'INFORMAZIONI GENERALI',
        fields: [
            {
                name: 'RAGIONE_SOCIALE',
                label: 'Ragione sociale',
                type: 'text',
                subType: 'text',
                maxLength: 100,
                required: true,
                placeholder: '',
                colspan: 8
            },
            {
                name: 'PIVA',
                label: 'Partita iva',
                type: 'text',
                subType: 'piva',
                maxLength: 11,
                required: true,
                placeholder: '',
                colspan: 2
            },
            {
                name: 'CODFISC',
                label: 'Codice fiscale',
                type: 'text',
                subType: 'cf',
                required: false,
                placeholder: '',
                colspan: 2
            },
            {
                name: 'INDIRIZZO',
                label: 'Indirizzo',
                type: 'text',
                subType: 'text',
                maxLength: 255,
                required: true,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'CIVICO',
                label: 'Civico',
                type: 'text',
                subType: 'text',
                maxLength: 20,
                required: true,
                colspan: 1
            },
            {
                name: 'CAP',
                label: 'Cap',
                type: 'text',
                subType: 'cap',
                maxLength: 5,
                required: true,
                colspan: 1
            },
            {
                name: 'COMUNE',
                label: 'Comune',
                type: 'text',
                subType: 'text',
                maxLength: 100,
                required: true,
                colspan: 3
            },
            {
                name: 'PROVINCIA',
                label: 'Provincia',
                type: 'text',
                subType: 'prov',
                maxLength: 2,
                required: true,
                colspan: 1
            },
            {
                name: 'REA_SIGLA',
                label: 'Rea sigla',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 2
            },
            {
                name: 'REA_NUMERO',
                label: 'Rea numero',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 2
            },
            {
                name: 'CCIAA',
                label: 'CCIAA',
                type: 'text',
                subType: 'text',
                maxLength: 8,
                required: false,
                colspan: 2
            },
            {
                name: 'FATT_CODICE_SDI',
                label: 'Codice SDI',
                type: 'text',
                subType: 'sdi',
                required: false,
                colspan: 2
            },
            {
                name: 'ID_REGIONE',
                label: 'Regione',
                type: 'select',
                options: [
                    { label: 'Piemonte', value: 1 },
                    { label: 'Valle d\'Aosta', value: 2 },
                    { label: 'Lombardia', value: 3 },
                    { label: 'Trentino Alto Adige', value: 4 },
                    { label: 'Veneto', value: 5 },

                    { label: 'Friuli Venezia Giulia', value: 6 },
                    { label: 'Liguria', value: 7 },
                    { label: 'Emilia Romagna', value: 8 },
                    { label: 'Toscana', value: 10 },
                    { label: 'Umbria', value: 11 },

                    { label: 'Marche', value: 12 },
                    { label: 'Lazio', value: 13 },
                    { label: 'Abruzzo', value: 15 },
                    { label: 'Molise', value: 16 },
                    { label: 'Campagna', value: 17 },

                    { label: 'Puglia', value: 18 },
                    { label: 'Basilicata', value: 19 },
                    { label: 'Calabria', value: 20 },
                    { label: 'Sicilia', value: 21 },
                    { label: 'Sardegna', value: 22 },

                ],
                required: false,
                colspan: 4
            },
        ],
    },
    {
        sectionTitle: 'CONTATTI PRINCIPALI',
        fields: [
            {
                name: 'TEL_FISSO',
                label: 'Telefono fisso',
                type: 'text',
                subType: 'phone',
                required: true,
                colspan: 2
            }
            ,
            {
                name: 'TEL_MOBILE',
                label: 'Cellulare',
                type: 'text',
                subType: 'phone',
                required: false,
                colspan: 2
            },
            {
                name: 'MAIL',
                label: 'Email Address',
                type: 'text',
                subType: 'mail',
                required: true,
                placeholder: 'Enter email',
                colspan: 4
            },
            {
                name: 'PEC',
                label: 'PEC',
                type: 'text',
                subType: 'mail',
                required: true,
                placeholder: 'Enter email',
                colspan: 4
            },
        ],
    },
    {
        sectionTitle: 'COORDINATE BANCARIE',
        fields: [
            {
                name: 'BANCA_ISTITUTO',
                label: 'Istituto',
                type: 'text',
                subType: 'text',
                maxLength: 100,
                required: true,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'BANCA_FILIALE',
                label: 'Filiale',
                type: 'text',
                subType: 'text',
                maxLength: 100,
                required: true,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'BANCA_IBAN',
                label: 'IBAN',
                type: 'text',
                subType: 'iban',
                required: true,
                placeholder: '',
                colspan: 2
            },
            {
                name: 'BANCA_SWIFT',
                label: 'SWIFT/BIC',
                type: 'text',
                subType: 'swift',
                required: true,
                placeholder: '',
                colspan: 2
            },
            {
                name: 'BANCA_INDIRIZZO',
                label: 'Indirizzo',
                type: 'text',
                subType: 'text',
                required: false,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'BANCA_CIVICO',
                label: 'Civico',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 1
            },
            {
                name: 'BANCA_CAP',
                label: 'Cap',
                type: 'text',
                subType: 'cap',
                maxLength: 5,
                required: false,
                colspan: 1
            },
            {
                name: 'BANCA_COMUNE',
                label: 'Comune',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 3
            },
            {
                name: 'BANCA_PROVINCIA',
                label: 'Provincia',
                type: 'text',
                subType: 'prov',
                maxLength: 2,
                required: false,
                colspan: 1
            },
        ],
    },
    {
        sectionTitle: 'LEGALE RAPPRESENTANTE',
        fields: [
            {
                name: 'LRAP_NOME',
                label: 'Nome',
                type: 'text',
                subType: 'text',
                required: true,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'LRAP_COGNOME',
                label: 'Cognome',
                type: 'text',
                subType: 'text',
                required: true,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'LRAP_CODFISC',
                label: 'Codice fiscale',
                type: 'text',
                subType: 'text',
                required: true,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'LRAP_INDIRIZZO',
                label: 'Indirizzo',
                type: 'text',
                subType: 'text',
                required: false,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'LRAP_CIVICO',
                label: 'Civico',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 1
            },
            {
                name: 'LRAP_CAP',
                label: 'Cap',
                type: 'text',
                subType: 'cap',
                maxLength: 5,
                required: false,
                colspan: 1
            },
            {
                name: 'LRAP_COMUNE',
                label: 'Comune',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 3
            },
            {
                name: 'LRAP_PROVINCIA',
                label: 'Provincia',
                type: 'text',
                subType: 'prov',
                maxLength: 2,
                required: false,
                colspan: 1
            },
            {
                name: 'LRAP_IN_QUALITA',
                label: 'In qualita di',
                type: 'select',
                options: [
                    { label: 'Legale rapp.', value: '0' },
                    { label: 'Amm. delegato', value: '1' },
                    { label: 'Amm. unico', value: '2' },
                    { label: 'Procuratore', value: '3' },
                    { label: 'Proprietario', value: '4' },
                    { label: 'Amm. protempore', value: '5' },
                ],
                required: true,
                colspan: 4
            },
            {
                name: 'COMUNE_NASCITA',
                label: 'Nato a',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 4
            },
            {
                name: 'DATA_NASCITA',
                label: 'Nato il',
                type: 'date',
                subType: 'text',
                required: false,
                colspan: 2
            },
            {
                type: 'emptyitem',
                colspan: 2
            },
            {
                name: 'LRAP_TEL_FISSO',
                label: 'Telefono fisso',
                type: 'text',
                subType: 'phone',
                required: false,
                colspan: 2
            }
            ,
            {
                name: 'LRAP_TEL_MOBILE',
                label: 'Cellulare',
                type: 'text',
                subType: 'phone',
                required: false,
                colspan: 2
            },
            {
                name: 'LRAP_MAIL',
                label: 'Email Address',
                type: 'text',
                subType: 'mail',
                required: true,
                placeholder: 'Enter email',
                colspan: 4
            },
            {
                name: 'LRAP_PEC',
                label: 'PEC',
                type: 'text',
                subType: 'mail',
                required: true,
                placeholder: 'Enter email',
                colspan: 4
            },
        ],
    },
];

// const formSchemaRuoliDocumenti = [
//     {
//         sectionTitle: 'INFORMAZIONI AGGIUNTIVE RUOLO AMMINISTRATORE',
//         fields: [
//             {
//                 name: 'adsasndipendenti',
//                 label: 'Numero dipendenti',
//                 type: 'text',
//                 required: true,
//                 placeholder: '',
//                 colspan: 2
//             },
//             {
//                 type: 'emptyitem',
//                 colspan: 10
//             },
//             {
//                 name: 'fafafncondomini',
//                 label: 'Numero condomini gestiti',
//                 type: 'text',
//                 required: true,
//                 placeholder: '',
//                 colspan: 2
//             },
//             {
//                 type: 'emptyitem',
//                 colspan: 10
//             },
//         ],
//     },
//     {
//         sectionTitle: 'INFORMAZIONI AGGIUNTIVE RUOLO IDRAULICA',
//         fields: [
//             {
//                 name: 'ddsndipendenti',
//                 label: 'Numero dipendenti',
//                 type: 'text',
//                 required: true,
//                 placeholder: '',
//                 colspan: 2
//             },
//             {
//                 type: 'emptyitem',
//                 colspan: 10
//             },
//         ],
//     },
// ];



//const formSchemaSample = [
//    {
//        sectionTitle: 'User Information',
//        fields: [
//            {
//                name: 'fullName',
//                label: 'Full Name',
//                type: 'text',
//                required: true,
//                placeholder: 'Enter full name',
//            },
//            {
//                name: 'email',
//                label: 'Email Address',
//                type: 'email',
//                required: true,
//                placeholder: 'Enter email',
//            },
//            {
//                name: 'password',
//                label: 'Password',
//                type: 'password',
//                required: true,
//                placeholder: 'Enter password',
//            },
//            {
//                name: 'birthday',
//                label: 'Date of Birth',
//                type: 'date',
//                required: false,
//            },
//        ],
//    },
//    {
//        sectionTitle: 'Preferences',
//        fields: [
//            {
//                name: 'colorPreference',
//                label: 'Favorite Color',
//                type: 'color',
//                required: false,
//            },
//            {
//                name: 'experienceLevel',
//                label: 'Experience Level',
//                type: 'range',
//                min: 0,
//                max: 10,
//                step: 1,
//                required: false,
//            },
//            {
//                name: 'developmentSkills',
//                label: 'Development Skills',
//                type: 'select',
//                options: [
//                    { label: 'Frontend', value: 'frontend' },
//                    { label: 'Backend', value: 'backend' },
//                    { label: 'Full Stack', value: 'fullstack' },
//                    { label: 'Data Science', value: 'datascience' },
//                ],
//                required: true,
//            },
//        ],
//    },
//    {
//        sectionTitle: 'Feedback',
//        fields: [
//            {
//                name: 'websiteFeedback',
//                label: 'Your Feedback',
//                type: 'textarea',
//                required: false,
//                placeholder: 'Share your thoughts',
//            },
//            {
//                name: 'termsAgreement',
//                label: 'Agree to Terms',
//                type: 'checkbox',
//                required: true,
//            },
//            {
//                name: 'fileUpload',
//                label: 'Upload File',
//                type: 'file',
//                required: false,
//            },
//            {
//                name: 'searchQuery',
//                label: 'Search',
//                type: 'search',
//                required: false,
//                placeholder: 'Search...',
//            },
//        ],
//    },
//];




const formSchemaDatiGeneraliOLD = [
    {
        sectionTitle: 'INFORMAZIONI GENERALI',
        fields: [
            {
                name: 'RAGIONE_SOCIALE',
                label: 'Ragione sociale',
                type: 'text',
                subType: 'text',
                maxLength: 100,
                required: true,
                placeholder: '',
                colspan: 8
            },
            {
                name: 'PIVA',
                label: 'Partita iva',
                type: 'text',
                subType: 'piva',
                maxLength: 11,
                required: true,
                placeholder: '',
                colspan: 4
            },
            ,
            {
                name: 'NOME',
                label: 'Nome',
                type: 'text',
                subType: 'text',
                required: false,
                placeholder: '',
                colspan: 4
            },
            ,
            {
                name: 'COGNOME',
                label: 'Cognome',
                type: 'text',
                subType: 'text',
                required: false,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'CODFISC',
                label: 'Codice fiscale',
                type: 'text',
                subType: 'cf',
                required: false,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'INDIRIZZO',
                label: 'Indirizzo',
                type: 'text',
                subType: 'text',
                maxLength: 255,
                required: true,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'CIVICO',
                label: 'Civico',
                type: 'text',
                subType: 'text',
                maxLength: 20,
                required: true,
                colspan: 1
            },
            {
                name: 'CAP',
                label: 'Cap',
                type: 'text',
                subType: 'cap',
                maxLength: 5,
                required: true,
                colspan: 1
            },
            {
                name: 'COMUNE',
                label: 'Comune',
                type: 'text',
                subType: 'text',
                maxLength: 100,
                required: true,
                colspan: 3
            },
            {
                name: 'PROVINCIA',
                label: 'Provincia',
                type: 'text',
                subType: 'prov',
                maxLength: 2,
                required: true,
                colspan: 1
            },
            {
                name: 'REA_SIGLA',
                label: 'Rea sigla',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 2
            },
            {
                name: 'REA_NUMERO',
                label: 'Rea numero',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 2
            },
            {
                name: 'CCIAA',
                label: 'CCIAA',
                type: 'text',
                subType: 'text',
                maxLength: 8,
                required: false,
                colspan: 2
            },
            {
                name: 'ID_REGIONE',
                label: 'Regione',
                type: 'select',
                options: [
                    { label: 'Piemonte', value: 1 },
                    { label: 'Valle d\'Aosta', value: 2 },
                    { label: 'Lombardia', value: 3 },
                    { label: 'Trentino Alto Adige', value: 4 },
                    { label: 'Veneto', value: 5 },
                    { label: 'Friuli Venezia Giulia', value: 6 },
                    { label: 'Liguria', value: 7 },
                    { label: 'Emilia Romagna', value: 8 },
                    { label: 'Toscana', value: 10 },
                    { label: 'Umbria', value: 11 },
                    { label: 'Marche', value: 12 },
                    { label: 'Lazio', value: 13 },
                    { label: 'Abruzzo', value: 15 },
                    { label: 'Molise', value: 16 },
                    { label: 'Campagna', value: 17 },
                    { label: 'Puglia', value: 18 },
                    { label: 'Basilicata', value: 19 },
                    { label: 'Calabria', value: 20 },
                    { label: 'Sicilia', value: 21 },
                    { label: 'Sardegna', value: 22 },

                ],
                required: false,
                colspan: 2
            },
            {
                name: 'FATT_CODICE_SDI',
                label: 'Codice SDI',
                type: 'text',
                subType: 'text',
                required: true,
                colspan: 4
            },

        ],
    },
    {
        sectionTitle: 'CONTATTI PRINCIPALI',
        fields: [
            {
                name: 'TEL_FISSO',
                label: 'Telefono fisso',
                type: 'text',
                subType: 'phone',
                required: false,
                colspan: 2
            }
            ,
            {
                name: 'TEL_MOBILE',
                label: 'Cellulare',
                type: 'text',
                subType: 'phone',
                required: true,
                colspan: 2
            },
            {
                name: 'MAIL',
                label: 'Email Address',
                type: 'text',
                subType: 'mail',
                required: true,
                placeholder: 'Enter email',
                colspan: 4
            },
            {
                name: 'PEC',
                label: 'PEC',
                type: 'text',
                subType: 'mail',
                required: true,
                placeholder: 'Enter email',
                colspan: 4
            },
        ],
    },
    {
        sectionTitle: 'COORDINATE BANCARIE',
        fields: [
            {
                name: 'BANCA_ISTITUTO',
                label: 'Istituto',
                type: 'text',
                subType: 'text',
                maxLength: 100,
                required: false,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'BANCA_FILIALE',
                label: 'Filiale',
                type: 'text',
                subType: 'text',
                maxLength: 100,
                required: false,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'BANCA_IBAN',
                label: 'IBAN',
                type: 'text',
                subType: 'iban',
                required: true,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'BANCA_SWIFT',
                label: 'SWIFT/BIC',
                type: 'text',
                subType: 'swift',
                required: false,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'BANCA_INDIRIZZO',
                label: 'Indirizzo',
                type: 'text',
                subType: 'text',
                required: false,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'BANCA_CIVICO',
                label: 'Civico',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 1
            },
            {
                name: 'BANCA_CAP',
                label: 'Cap',
                type: 'text',
                subType: 'cap',
                maxLength: 5,
                required: false,
                colspan: 1
            },
            {
                name: 'BANCA_COMUNE',
                label: 'Comune',
                type: 'text',
                subType: 'text',
                required: false,
                colspan: 3
            },
            {
                name: 'BANCA_PROVINCIA',
                label: 'Provincia',
                type: 'text',
                subType: 'prov',
                maxLength: 2,
                required: false,
                colspan: 1
            },
        ],
    },
    {
        sectionTitle: 'LEGALE RAPPRESENTANTE',
        fields: [
            {
                name: 'LRAP_NOME',
                label: 'Nome',
                type: 'text',
                subType: 'text',
                required: true,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'LRAP_COGNOME',
                label: 'Cognome',
                type: 'text',
                subType: 'text',
                required: true,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'LRAP_CODFISC',
                label: 'Codice fiscale',
                type: 'text',
                subType: 'text',
                required: true,
                placeholder: '',
                colspan: 4
            },
            {
                name: 'LRAP_INDIRIZZO',
                label: 'Indirizzo',
                type: 'text',
                subType: 'text',
                required: true,
                placeholder: '',
                colspan: 6
            },
            {
                name: 'LRAP_CIVICO',
                label: 'Civico',
                type: 'text',
                subType: 'text',
                required: true,
                colspan: 1
            },
            {
                name: 'LRAP_CAP',
                label: 'Cap',
                type: 'text',
                subType: 'cap',
                maxLength: 5,
                required: true,
                colspan: 1
            },
            {
                name: 'LRAP_COMUNE',
                label: 'Comune',
                type: 'text',
                subType: 'text',
                required: true,
                colspan: 3
            },
            {
                name: 'LRAP_PROVINCIA',
                label: 'Provincia',
                type: 'text',
                subType: 'prov',
                maxLength: 2,
                required: true,
                colspan: 1
            },
            {
                name: 'LRAP_IN_QUALITA',
                label: 'In qualita di',
                type: 'select',
                options: [
                    { label: 'Legale rapp.', value: 0 },
                    { label: 'Amm. delegato', value: 1 },
                    { label: 'Amm. unico', value: 2 },
                    { label: 'Procuratore', value: 3 },
                    { label: 'Proprietario', value: 4 },
                    { label: 'Amm. protempore', value: 5 },
                ],
                required: true,
                colspan: 4
            },
            {
                name: 'COMUNE_NASCITA',
                label: 'Nato a',
                type: 'text',
                subType: 'text',
                required: true,
                colspan: 4
            },
            {
                name: 'DATA_NASCITA',
                label: 'Nato il',
                type: 'date',
                subType: 'text',
                required: true,
                colspan: 2
            },
            {
                type: 'emptyitem',
                colspan: 2
            },
            {
                name: 'LRAP_TEL_FISSO',
                label: 'Telefono fisso',
                type: 'text',
                subType: 'phone',
                required: false,
                colspan: 2
            }
            ,
            {
                name: 'LRAP_TEL_MOBILE',
                label: 'Cellulare',
                type: 'text',
                subType: 'phone',
                required: true,
                colspan: 2
            },
            {
                name: 'LRAP_MAIL',
                label: 'Email Address',
                type: 'text',
                subType: 'mail',
                required: true,
                placeholder: 'Enter email',
                colspan: 4
            },
            {
                name: 'LRAP_PEC',
                label: 'PEC',
                type: 'text',
                subType: 'mail',
                required: true,
                placeholder: 'Enter email',
                colspan: 4
            },
        ],
    },
];