import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './dx-styles.scss';

import { HashRouter as Router } from 'react-router-dom';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';

import config from 'devextreme/core/config';

import itMessages from 'devextreme/localization/messages/it.json';
import { locale, loadMessages } from 'devextreme/localization';

locale(sessionStorage.getItem('locale') || 'it');
loadMessages(itMessages);


function App() {

    //LICENZA DEVEXTREME 24
    const licenseKey = 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjY3YjBkYWU3LWVhOGYtNDA4MC05YzU4LTU2ZDAwMTVhMDZkZCIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.gOMvKRd7WIwDAPNatofKfjo66vHOnQVSv/wEdyB23bg9pL40/qY7WC0ig6g8rBo5LFAMtWnDhjsZglRoI98KqddA8i1oE8S3w8jmqjjyyeMxfqsWvUNYrDAP321noAfS3QjzLA==';
    config({ licenseKey });

    const { user, loading } = useAuth();

    const screenSizeClass = useScreenSizeClass();

    if (loading) {
        return <div className={`app ${screenSizeClass}`}><LoadPanel visible={true} /></div>;
    }

    if (user) {
        return <div className={`app ${screenSizeClass}`}><Content /></div>;
    }

    return <UnauthenticatedContent />;
}

export default function AppMainContent() {

    return (
        <Router>
            <AuthProvider>
                <NavigationProvider>
                    <App />
                </NavigationProvider>
            </AuthProvider>
        </Router>
    );
}
