/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import axios from 'axios';
import appInfo from '../../app-info';
import './home.scss';

import shieldGreen from '../../Images/Security_Security.svg';
import shieldYellow from '../../Images/Security_Security bicolor 2.svg';
import shieldRed from '../../Images/Security_Security red.svg';

import { useAuth } from '../../contexts/auth';
import Verticalmemolist from '../../components/vertical-memo-list/vertical-memo-list';


const url = appInfo.urlWebApi;

export default function App() {

    const { user } = useAuth();

    async function GetLogo() {
        const { user } = useAuth();

        if (user) {

            var imgLogo = null;

            const instance = axios.create({
                baseURL: url,
                timeout: 50000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            await instance.get('/GetLogo', {
                params: { InternalKey: user.internalKey, userType: user.userType, id: user.id }
            })
                .then(function (response) {
                    if (response.data != null) {
                        imgLogo = { isOk: true, data: response.data };
                    }
                    else {
                        imgLogo = { isOk: false, message: response.data.message, data: null };
                    }
                })
                .catch(function () {
                    imgLogo = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });

            return imgLogo;
        }
        else {
            return null;
        }
    }
    GetLogo().then(function (result) {
        console.log(result);
        if (result.data != null) {
            document.getElementById("imageLogo").src = result.data.replace(/(\r\n|\n|\r)/gm, "");
            document.getElementById("imageLogo").className = 'visible';
        }
        return ""
    });

    async function GetLogoUtente() {
        const { user } = useAuth();

        if (user) {

            var imgLogo = null;

            const instance = axios.create({
                baseURL: url,
                timeout: 50000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            await instance.get('/GetLogoUtente', {
                params: { InternalKey: user.internalKey, userType: user.userType, id: user.id }
            })
                .then(function (response) {
                    if (response.data != null) {
                        imgLogo = { isOk: true, data: response.data };
                    }
                    else {
                        imgLogo = { isOk: false, message: response.data.message, data: null };
                    }
                })
                .catch(function () {
                    imgLogo = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });

            return imgLogo;
        }
        else {
            return null;
        }
    }
    GetLogoUtente().then(function (result) {
        console.log(result);
        if (result.data != null) {
            document.getElementById("imageLogoUtente").src = result.data.replace(/(\r\n|\n|\r)/gm, "");
            document.getElementById("imageLogoUtente").className = 'visible';
        }
        return ""
    });

    async function GetDocumenti() {
        if (user) {

            var result = null;

            const instance = axios.create({
                baseURL: url,
                timeout: 3000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            await instance.get('/GetDocumentiCheck', {
                params: { InternalKey: user.internalKey, userType: user.userType, id: user.id }
            })
                .then(function (response) {
                    if (response.data != null) {
                        result = { isOk: true, data: response.data };
                    }
                    else {
                        result = { isOk: false, message: response.data.message, data: null };
                    }
                })
                .catch(function () {
                    result = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });

            return result;
        }
        else {
            return null;
        }
    }
    GetDocumenti().then(async function (result) {

        if (user.docAllowed) {
            document.getElementById("Validi").innerText = result.data.Validi;
            document.getElementById("Scaduti").innerText = result.data.Scaduti;
            document.getElementById("Mancanti").innerText = result.data.Mancanti;
            document.getElementById("shield").src = result.data.Mancanti > 0 ? shieldRed : result.data.Scaduti > 0 ? shieldYellow : shieldGreen;

            document.getElementById("linkDocumenti").title =
                result.data.Mancanti > 0 ? 'Presenti documenti MANCANTI - Vai alla sezione relativa ai documenti' :
                    result.data.Scaduti > 0 ? 'Presenti documenti SCADUTI - Vai alla sezione relativa ai documenti' :
                        'Vai alla sezione relativa ai documenti';
        }
        else {
            document.getElementById("linkDocumenti").hidden = 'hidden';
            document.getElementById("securecheck").hidden = 'hidden';
        }



        return ""
    });

    async function GetHomePage() {
        const { user } = useAuth();

        if (user) {

            var result = null;

            const instance = axios.create({
                baseURL: url,
                timeout: 50000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            await instance.get('/GetHomePage', {
                params: { InternalKey: user.internalKey, userType: user.userType, id: user.id }
            })
                .then(function (response) {
                    if (response.data != null) {
                        result = { isOk: true, data: response.data };
                    }
                    else {
                        result = { isOk: false, message: response.data.message, data: null };
                    }
                })
                .catch(function () {
                    result = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });

            return result;
        }
        else {
            return null;
        }
    }
    GetHomePage().then(async function (result) {
        console.log(result);
        document.getElementById("descrizioneSede").innerText = result.data.descrizioneSede;
        document.getElementById("indirizzoSede").innerText = result.data.indirizzoSede;
        document.getElementById("webSiteSede").innerText = result.data.webSiteSede;
        document.getElementById("webSiteSede").href = result.data.webSiteSede;
        document.getElementById("emailSede").innerText = result.data.emailSede;
        document.getElementById("emailSede").href = result.data.emailSede;

        document.getElementById("descrizioneUtente").innerText = result.data.descrizioneUtente;
        document.getElementById("indirizzoUtente").innerText = result.data.indirizzoUtente;
        document.getElementById("telefonoUtente").innerText = result.data.telefonoUtente;
        document.getElementById("emailUtente").innerText = result.data.emailUtente;
        document.getElementById("emailUtente").href = result.data.emailUtente;

        if (result.data.testo1 != null && result.data.testo1 != '') {
            if (result.data.link1 != null) {
                document.getElementById("Avviso1").innerHTML = "<a target={'_blank'} href='" + result.data.link1 + "' rel={'noopener noreferrer'}>" + result.data.testo1 + "</a>"
            }
            else {
                document.getElementById("Avviso1").innerText = result.data.testo1;
            }
        }
        else {
            document.getElementById("Avviso1").hidden = "hidden";
        }
        if (result.data.testo2 != null && result.data.testo2 != '') {
            if (result.data.link2 != null) {
                document.getElementById("Avviso2").innerHTML = "<a target={'_blank'} href='" + result.data.link2 + "' rel={'noopener noreferrer'}>" + result.data.testo2 + "</a>"
            }
            else {
                document.getElementById("Avviso2").innerText = result.data.testo2;
            }
        }
        else {
            document.getElementById("Avviso2").hidden = "hidden";
        }
        if (result.data.testo3 != null && result.data.testo3 != '') {
            if (result.data.link3 != null) {
                document.getElementById("Avviso3").innerHTML = "<a target={'_blank'} href='" + result.data.link3 + "' rel={'noopener noreferrer'}>" + result.data.testo3 + "</a>"
            }
            else {
                document.getElementById("Avviso3").innerText = result.data.testo3;
            }
        }
        else {
            document.getElementById("Avviso3").hidden = "hidden";
        }
        if (result.data.testo4 != null && result.data.testo4 != '') {
            if (result.data.link4 != null) {
                document.getElementById("Avviso4").innerHTML = "<a target={'_blank'} href='" + result.data.link4 + "' rel={'noopener noreferrer'}>" + result.data.testo4 + "</a>"
            }
            else {
                document.getElementById("Avviso4").innerText = result.data.testo4;
            }
        }
        else {
            document.getElementById("Avviso4").hidden = "hidden";
        }
        if (result.data.testo5 != null && result.data.testo5 != '') {
            if (result.data.link5 != null) {
                document.getElementById("Avviso5").innerHTML = "<a target={'_blank'} href='" + result.data.link5 + "' rel={'noopener noreferrer'}>" + result.data.testo5 + "</a>"
            }
            else {
                document.getElementById("Avviso5").innerText = result.data.testo5;
            }
        }
        else {
            document.getElementById("Avviso5").hidden = "hidden";
        }
        return ""
    });

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Home</h2>

            <div className={'content-block'} >
                <div className={'dx-card responsive-paddings full-background'}>
                    <div className={'logos-container'}>
                        <div>
                            <div className='content-horizontal'>
                                <div className='logo-size'>
                                    <img id="imageLogo" height="130" alt="Logo azienda" className='hidden' />
                                </div>
                                <div>
                                    <p><b><span id="descrizioneSede" /></b></p>
                                    <p>
                                        <span><span id="indirizzoSede" /></span><br></br>
                                        Web Site : <a id="webSiteSede" target={'_blank'} rel={'noopener noreferrer'}></a><br></br>
                                        Email : <a id="emailSede" target={'_blank'} rel={'noopener noreferrer'}></a>
                                    </p>
                                </div>
                            </div>
                            <br></br>
                            <div className='content-horizontal'>
                                <div className='logo-size'>
                                    <img id="imageLogoUtente" height="130" alt="Logo utente" className='hidden' />
                                </div>
                                <div>
                                    <p><b><span id="descrizioneUtente" /></b></p>
                                    <p>
                                        <span><span id="indirizzoUtente" /></span><br></br>
                                        Telefono : <span><span id="telefonoUtente" /></span><br></br>
                                        Email : <a id="emailUtente" target={'_blank'} rel={'noopener noreferrer'}></a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={'item2'}>
                        </div>

                        <div className={'item-padding'} >
                            <p id="securecheck">
                                <p><b>SecureCheck summary</b></p>
                                <span className={'validi'}>Documenti validi</span><span id="Validi">0</span><br></br>
                                <span className={'scaduti'}>Documenti scaduti</span><span id="Scaduti">0</span><br></br>
                                <span className={'mancanti'}>Documenti mancanti</span><span id="Mancanti">0</span>
                            </p>
                        </div>
                        <a href={'/#/documenti'} id="linkDocumenti" title="Vai alla sezione relativa ai documenti">
                            <img id="shield" height="200" src={shieldYellow} alt="Shield" className={'item1'} />
                        </a>
                    </div>

                    <div className={'home-section-top'}>
                    </div>

                    <div className={'home-section-bottom'}>
                        <p><b>AVVISI, COMUNICAZIONI E NUOVE INIZIATIVE</b></p>

                        <ul>
                            <li id="Avviso1"></li>
                            <li id="Avviso2"></li>
                            <li id="Avviso3"></li>
                            <li id="Avviso4"></li>
                            <li id="Avviso5"></li>
                        </ul>
                        <br></br>
                        <br></br>
                        <p>
                            <span>For technical content related to ADV Project S.r.l., VulcanoSmart software, feel free to explore our </span>
                            <a href="http://www.advproject.it" target="_blank" rel="noopener noreferrer">online documentation</a>
                            <span> and </span>
                            <a href="http://www.advproject.it" target="_blank" rel="noopener noreferrer">technical informations</a>.
                        </p>
                    </div>
                </div>
            </div>

            <div className="options" hidden>
                <div className="caption">Dashboard</div>
                <div className="option">

                    <Verticalmemolist pippo='10' />

                </div>
            </div>

        </React.Fragment>
    );
}







