
import React, { useState } from 'react';
import Form, { Item, GroupItem, ButtonOptions, Label, ButtonItem, RequiredRule } from 'devextreme-react/form';
import { PatternRule } from 'devextreme-react/validator';


const DynamicForm = ({ schema, dataform, onSubmit }) => {

    const [formData] = useState(dataform);
    const submitButtonAttributes = { class: 'submit-button' };

    const handleChange = (e) => {
        //const { name, value, type, checked } = e.target;
        //setFormData({
        //    ...formData,
        //    [name]: type === 'checkbox' ? checked : value,
        //});
    };
    const handleValidation = () => {
        let formIsValid = true;

        schema.forEach((section) => {
            if (section.fields) {
                section.fields.forEach((field) => {
                    if (field.required && !formData[field.name]) {
                        formIsValid = false;
                    }
                });
            }
        });

        return formIsValid;
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (handleValidation()) {
            onSubmit(formData);
        }
    };

    const numIntPattern = /^[0-9]+$/;
    const numFloatPattern = /^[+-]?([0-9]*[.])?[0-9]+$/;
    const mailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phonePattern = /^(\+39)?\s?(\d{2,4})?\s?\d{6,10}$/;
    const capPattern = /^\d{5}$/;
    const provPattern = /^[A-Z]{2}$/;
    const cfPattern = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/;
    const pivaPattern = /^[0-9]{11}$/;
    const ibanPattern = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
    const swiftPattern = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
    const sdiPattern = /^[A-Z0-9]{7}$/;


    const renderField = (field) => {
        switch (field.type) {
            case 'text':
                return (
                    <Item field={field} name={field.name} dataField={field.name} editorType={'dxTextBox'} onChange={handleChange} editorOptions={{ maxLength: field.maxLength }}>
                        <Label text={field.label} />
                        {field.required === true && (<RequiredRule type="required" message="Richiesto" />)}
                        {field.subType === 'mail' && (<PatternRule pattern={mailPattern} message="Indirizzo email non valido" />)}
                        {field.subType === 'phone' && (<PatternRule pattern={phonePattern} message="Formato non valido" />)}
                        {field.subType === 'cap' && (<PatternRule pattern={capPattern} message="Formato non valido" />)}
                        {field.subType === 'prov' && (<PatternRule pattern={provPattern} message="Formato non valido" />)}
                        {field.subType === 'cf' && (<PatternRule pattern={cfPattern} message="Formato non valido" />)}
                        {field.subType === 'piva' && (<PatternRule pattern={pivaPattern} message="Formato non valido" />)}
                        {field.subType === 'iban' && (<PatternRule pattern={ibanPattern} message="Formato non valido" />)}
                        {field.subType === 'swift' && (<PatternRule pattern={swiftPattern} message="Formato non valido" />)}
                        {field.subType === 'sdi' && (<PatternRule pattern={sdiPattern} message="Formato non valido" />)}
                    </Item>
                );
            case 'password':
                return (
                    <Item field={field} name={field.name} dataField={field.name} editorType={'dxTextBox'} onChange={handleChange} editorOptions={{ maxLength: field.maxLength, stylingMode: 'filled', mode: 'password' }}>
                        <Label text={field.label} />
                        {field.required === true && (<RequiredRule type="required" message="Richiesto" />)}
                    </Item>
                );
            case 'numeric':
                return (
                    <Item field={field} name={field.name} dataField={field.name} editorType={'dxNumberBox'} onChange={handleChange}>
                        <Label text={field.label} />
                        {field.required === true && (<RequiredRule type="required" message="Richiesto" />)}
                        {field.subType === 'int' && (<PatternRule pattern={numIntPattern} message="Formato numerico non valido" />)}
                        {field.subType === 'float' && (<PatternRule pattern={numFloatPattern} message="Formato numerico con virgola non valido" />)}
                    </Item>
                );
            case 'select':
                return (
                    <Item name={field.name} dataField={field.name} editorType={'dxSelectBox'} onChange={handleChange}
                        editorOptions={{ items: field.options, searchEnabled: true, valueExpr: 'value', displayExpr: 'label' }}>
                        <Label text={field.label} />
                        {field.required === true && (<RequiredRule type="required" message="Richiesto" />)}
                    </Item>
                );
            case 'radio':
                return (
                    <Item name={field.name} dataField={field.name} editorType={'dxRadioGroup'} isRequired={field.required} onChange={handleChange}>
                        <Label text={field.label} />
                    </Item>
                );
            case 'checkbox':
                return (
                    <Item name={field.name} dataField={field.name} editorType={'dxCheckBox'} onChange={handleChange}>
                        <Label text={field.label} />
                    </Item>
                );
            case 'date':
                return (
                    <Item name={field.name} dataField={field.name} editorType={'dxDateBox'} onChange={handleChange}>
                        <Label text={field.label} />
                        {field.required === true && (<RequiredRule type="required" message="Richiesto" />)}
                    </Item>
                );
            case 'color':
                return (
                    <Item name={field.name} dataField={field.name} editorType={'dxColorBox'} onChange={handleChange}>
                        <Label text={field.label} />
                    </Item>
                );
            case 'range':
                return (
                    <Item name={field.name} dataField={field.name} editorType={'dxSlider'} onChange={handleChange}>
                        <Label text={field.label} />
                    </Item>
                );
            case 'textarea':
                return (
                    <Item name={field.name} dataField={field.name} editorType={'dxTextArea'} onChange={handleChange} editorOptions={{ maxLength: field.maxLength, height: 140 }}>
                        <Label text={field.label} />
                        {field.required === true && (<RequiredRule type="required" message="Richiesto" />)}
                    </Item>
                );
            case 'emptyitem':
                return (
                    <Item editorType={'dxEmptyItem'} />
                );

            default:
                return (
                    <Item editorType={'dxEmptyItem'}>
                        <Label text={'Errore, tipo campo non definito'} />
                    </Item>
                );
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Form colSpan={2} formData={formData} showValidationSummary={false}>
                <Item itemType="group" key={new Date().toString() + Math.floor(Math.random() * 100).toString()}>
                    {schema.map((section, sectionIndex) => (
                        <GroupItem key={sectionIndex} caption={section.sectionTitle} colCount={12}>
                            {section.fields &&
                                section.fields.map((field, fieldIndex) => (
                                    <Item key={fieldIndex} itemType="group" colSpan={field.colspan}>
                                        {renderField(field)}
                                    </Item>
                                ))}
                        </GroupItem>
                    ))}
                </Item>

                <ButtonItem>
                    <ButtonOptions
                        elementAttr={submitButtonAttributes}
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}>
                        <span className="dx-button-text">Salva</span>
                    </ButtonOptions>

                </ButtonItem>
            </Form>
        </form>
    );
};

export default DynamicForm;