
import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation, navigationNoConsultazione, navigationNoConsultazioneNoDoc, navigationNoDoc, navigationNoLet, navigationNoLetNoDoc, navigationNoRee, navigationNoReeNoDoc, navigationActor, navigationActorNoDoc, navigationActorNoDocNoMobile, navigationActorNoMobile, navigationAmm, navigationAmmNoMobile, navigationAmmNoRee, navigationAmmNoReeNoMobile, navigationAmmNoReeNoDocNoMobile, navigationAmmNoLetNoMobile, navigationAmmNoLetNoDocNoMobile, navigationAmmNoConsultazioneNoDocNoMobile, navigationAmmNoDocNoMobile, navigationAmmNoReeNoDoc, navigationAmmNoLet, navigationAmmNoLetNoDoc, navigationAmmNoConsultazione, navigationAmmNoConsultazioneNoMobile, navigationAmmNoConsultazioneNoDoc, navigationAmmNoDoc, navigationUtenti, navigationCallCenter } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './side-navigation-menu.scss';
import { useAuth } from '../../contexts/auth';

import * as events from 'devextreme/events';


export default function SideNavigation(props) {

    const { user } = useAuth();

    const {
        children,
        selectedItemChanged,
        openMenu,
        compactMode,
        onMenuReady
    } = props;
        
    const { isLarge } = useScreenSize();

    function normalizePath() {
        if (user.userType === 0)    // CLIENTE
        {  
            if (user.letAllowed && !user.reeAllowed) {
                return navigationNoReeNoDoc.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && user.reeAllowed) {
                return navigationNoLetNoDoc.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && !user.reeAllowed) {
                return navigationNoConsultazioneNoDoc.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (user.letAllowed && user.reeAllowed) {
                return navigationNoDoc.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            }
        }
        else if (user.userType === 1)   // ATTORE
        {   
            if (user.docAllowed && user.mobileAllowed) {
                return navigationActor.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            }
            else if (user.docAllowed && !user.mobileAllowed) {
                return navigationActorNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            }
            else if (!user.docAllowed && user.mobileAllowed) {
                return navigationActorNoDoc.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            }
            else {
                return navigationActorNoDocNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            }

        }
        else if (user.userType === 2)   // AMMINISTRATORE
        {   
            if (user.letAllowed && user.reeAllowed && user.docAllowed && user.mobileAllowed) {
                return navigationAmm.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (user.letAllowed && user.reeAllowed && user.docAllowed && !user.mobileAllowed) {
                return navigationAmmNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (user.letAllowed && !user.reeAllowed && user.docAllowed && user.mobileAllowed) {
                return navigationAmmNoRee.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (user.letAllowed && !user.reeAllowed && user.docAllowed && !user.mobileAllowed) {
                return navigationAmmNoReeNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (user.letAllowed && !user.reeAllowed && !user.docAllowed && user.mobileAllowed) {
                return navigationAmmNoReeNoDoc.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (user.letAllowed && !user.reeAllowed && !user.docAllowed && !user.mobileAllowed) {
                return navigationAmmNoReeNoDocNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && user.reeAllowed && user.docAllowed && user.mobileAllowed) {
                return navigationAmmNoLet.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && user.reeAllowed && user.docAllowed && !user.mobileAllowed) {
                return navigationAmmNoLetNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && user.reeAllowed && !user.docAllowed && user.mobileAllowed) {
                return navigationAmmNoLetNoDoc.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && user.reeAllowed && !user.docAllowed && !user.mobileAllowed) {
                return navigationAmmNoLetNoDocNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && !user.reeAllowed && user.docAllowed && user.mobileAllowed) {
                return navigationAmmNoConsultazione.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && !user.reeAllowed && user.docAllowed && !user.mobileAllowed) {
                return navigationAmmNoConsultazioneNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && !user.reeAllowed && !user.docAllowed && user.mobileAllowed) {
                return navigationAmmNoConsultazioneNoDoc.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (!user.letAllowed && !user.reeAllowed && !user.docAllowed && !user.mobileAllowed) {
                return navigationAmmNoConsultazioneNoDocNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (user.letAllowed && user.reeAllowed && !user.docAllowed && user.mobileAllowed) {
                return navigationAmmNoDoc.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            } else if (user.letAllowed && user.reeAllowed && !user.docAllowed && !user.mobileAllowed) {
                return navigationAmmNoDocNoMobile.map((item) => {
                    if (item.path && !(/^\//.test(item.path))) {
                        item.path = `/${item.path}`;
                    }
                    return { ...item, expanded: isLarge };
                });
            }
        }
        else if (user.userType === 3)   // UTENTE
        {  
            return navigationUtenti.map((item) => {
                if (item.path && !(/^\//.test(item.path))) {
                    item.path = `/${item.path}`;
                }
                return { ...item, expanded: isLarge };
            });
        }
        else if (user.userType === 4)   // CallCenter
        {  
            return navigationCallCenter.map((item) => {
                if (item.path && !(/^\//.test(item.path))) {
                    item.path = `/${item.path}`;
                }
                console.log('***********ITEM**********');
                console.log(item.path);
                return { ...item, expanded: isLarge };
            });
        }
    }


    const items = useMemo(
        normalizePath,
        [normalizePath]
    );

    const { navigationData: { currentPath } } = useNavigation();

    const treeViewRef = useRef();
    const wrapperRef = useRef();
    const getWrapperRef = useCallback((element) => {
        const prevElement = wrapperRef.current;
        if (prevElement) {
            events.off(prevElement, 'dxclick');
        }

        wrapperRef.current = element;
        events.on(element, 'dxclick', e => {
            openMenu(e);
        });
    }, [openMenu]);

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }

        if (currentPath !== undefined) {
            treeView.selectItem(currentPath);
            treeView.expandItem(currentPath);
        }

        if (compactMode) {
            treeView.collapseAll();
        }
    }, [currentPath, compactMode]);

    return (
        <div
            className={'dx-swatch-additional side-navigation-menu'}
            ref={getWrapperRef}
        >
            {children}
            <div className={'menu-container'}>
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    keyExpr={'path'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    expandEvent={'click'}
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width={'100%'}
                />
            </div>
        </div>
    );
}
