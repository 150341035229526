/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState, useRef, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './login-form.scss';


export default function AppLogin() {

    const { signIn } = useAuth();
    const [loading, setLoading] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search);
    const a = queryParameters.get("a");
    const b = queryParameters.get("b");
    const c = queryParameters.get("c");

    const [forceEntrance] = useState(a !== null);

    const formData = (a == null && b == null) ? useRef({}) : useRef({ username: a, password: b, target: c });


    const onFormSubmit = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);

        const { username, password, target } = formData.current;
        setLoading(true);

        const result = await signIn(username, password, (a != null && b != null), target);
        if (!result.isOk) {
            setLoading(false);
            notify(result.message, 'error', 2000);
        }
        e.stopPropagation();
    }, [signIn]);

    const onChange = useCallback(async (e) => {
        e.preventDefault();
        if (e.target.id === 'formBasicUsername') { formData.current.username = e.target.value; }
        if (e.target.id === 'formBasicPassword') { formData.current.password = e.target.value; }
    }, [formData]);


    return (

        <Form onSubmit={onFormSubmit} onChange={onChange}>
            <Form.Group className="mb-3" controlId="formBasicUsername">
                <p className="text-muted mb-2">Credenziali</p>
                <Form.Control type="input" placeholder="Username" onChange={onChange} defaultValue={formData.current.username} disabled={forceEntrance === true || (forceEntrance === false && loading)} className='form-control rounded-pill border-0 shadow-sm px-4 form-text' />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control type="password" placeholder="Password" onChange={onChange} defaultValue={formData.current.password} disabled={forceEntrance === true || (forceEntrance === false && loading)} className="form-control rounded-pill border-0 shadow-sm px-4 text-primary" />
            </Form.Group>

            <Form.Group className="form-check" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Ricordami" />
            </Form.Group>

            <div className="d-grid gap-2 mt-4">
                <Button variant="primary" type="submit" disabled={loading} className='btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm'>
                    <span className="dx-button-text" >
                        {
                            loading
                                ? <LoadIndicator className='loadIndicator-white' id="loadIndicator" width={'19px'} height={'19px'} visible={true} />
                                : 'ENTRA'
                        }
                    </span>
                </Button>
            </div>

            <div className="text-center d-flex justify-content-between mt-4"><p>Sviluppato da <a href="#" className="font-italic text-muted">
                <u>ADV Project S.r.l.</u></a></p></div>
        </Form>
    );
}
